/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
export const HOME_PATH = '/';
export const DASHBOARD_PATH = '/dashboard';
export const LIBRARY_PATH = '/library';
export const TUTORIALS_PATH = '/tutorials';
export const EXPLORE_PATH = '/article/explore';
export const LEARNING_LIBRARY_PATH = '/learningLibrary';
export const MARKETPLACE_PATH = '/marketplace';
export const RECOMMENDED_READING_PATH = '/recommendedReading';
export const CONFIRM_EMAIL = '/confirm_email';
export const TUTORIAL_PATH = '/tutorial';
export const QUESTION_PATH = '/question';
export const EXAM_QUESTION_PATH = '/exam/question';
export const DIRECTION_PATH = '/section';
export const EXAM_DIRECTION_PATH = '/exam/section';
export const PASSAGE_PATH = '/passage';
export const EXAM_PASSAGE_PATH = '/exam/passage';
export const HISTORY_PATH = '/history';
export const TEST_COMPLETE_PATH = '/testComplete';
export const EXAM_TEST_COMPLETE_PATH = '/exam/testComplete';
export const TEST_REVIEW_PATH = '/testReview';
export const EXAM_TEST_REVIEW_PATH = '/exam/testReview';
export const MODULE_PATH = '/module';
export const EXAM_MODULE_PATH = '/exam/module';
export const UPGRADE_PATH = '/buy';
export const ACCOUNT_SETTINGS_PATH = '/account';
export const STATIC_PAGE_PATH = '/static';
export const TEST_COMPLETE_FLEX_PATH = '/exam/examTestComplete';
export const LSAT_FLEX_PATH = '/flex';
export const FLEX_LANDING_PATH = '/exam/flexBegin';
export const CERTIFYING_STATEMENT_PATH = '/exam/certifyingStatement';
export const SCORE_REPORT_PATH = '/scoreReport';
export const CONNECTIVITY_ERROR_PATH = '/connectivityError';
export const FLEX_CONGRATS_PATH = '/exam/examCongratulations';
export const PROMETRICS_COMPLETE_PATH = '/exam/examTestCompletion';
export const EXAM_START_PATH = '/examStart';
export const EXAM_LAUNCHER = '/examLauncher';
export const EXAM_COMPLETE_PATH = '/exam/examComplete';
export const WRITING_EXAM_COMPLETE_FINAL_PATH = '/exam/WritingExamComplete';
export const INJECTED_FIELD_NAME = 'password';
export const INJECTED_STORAGE_NAME = 'injectedHash';
export const INJECTED_BEFORE_REACT_LOAD = 'wasInjectedBeforeReactLoad';
export const EXAM_CONFIRM_COMPLETE_PATH = '/exam/examConfirmComplete';
export const BROWSER_ERROR_PATH = '/browserError';
export const INTERMISSION_PATH = '/exam/intermission';
export const PLUS_PROGRAM_REGISTRATION = '/plusprogramregistration';
export const PLUS_PROGRAM_REGISTRATION_COMPLETE = '/plusRegComplete';
export const WRITING_EXAM_PAGE = '/writingExamPage';
export const PROCTORING_INITIALIZE_PATH = '/proctoringStart';
export const EVENT_DETAILS_PATH = '/eventDetails';
export const ARTICLE_PATH = '/article';
export const EVENT_JOIN_PATH = '/eventJoin';
export const PROGRAM_DETAILS_PATH = '/programs';
export const STARTUP_PATH = '/startup';
export const LOGIN_PATH = '/login';
export const API_ERROR_PATH = '/apiError';
export const OFFLINE_ERROR_PATH = '/exam/offline';
export const LSAC_ORGANIZATION_IDENTIFIER_TYPE = 'LSACOnlineServicesNumber';
export const APPLICATION_ONLINE_STATUS_PAGE = '/applications';
export const UPGRADE_AST_PATH = '/buy?attribution=ast';
export const UPGRADE_TOOLS_PAGE_PATH = '/buy?attribution=tools';
export const EXAM_PAUSE_PATH = '/exam/pause';
export const LEARNING_COLLECTION_PATH = '/learningCollection';
export const TOOLS_PATH = '/tools';
export const DEEPLINK_PATH = '/ref/:moduleId/:vendorId?';
export const TEST_DURATION_ENDED = '/exam/testDurationEnded';
export const ERROR_SCREEN_PATH = '/error';
export const WRITING_ERROR_SCREEN_PATH = '/writing-error';
export const SIGNUP_PAGE_PATH = '/signup';
export const DRILLSET_PATH = '/library/drillsets';
export const FULLTEST_PATH = '/library/fulltests';
export const ADDITIONAL_PRACTICE_TEST_PATH = '/library/additionalpractice';
export const PODCAST_PATH = '/podcasts';
export const ZOOM_LEAVE_URL = '/events';
export const LST_PATH = '/law-school-transparency';
