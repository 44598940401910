/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/**
 *
 * instance state management helper
 *
 */
import { config } from 'config';
import { isNullOrUndefined } from 'util';
import * as constants from 'containers/DirectionsPage/constants';
import { remove } from 'lodash';
import { getUserId } from '../userHelper';
import { currentTimeAsISO } from '../timeHelper';
import { logException } from '../logHelper';
import { getBrowserName } from '../browserHelper';
import { checkForDuplicates } from './repoManagementHelper';
import {
  checkForSaveToDb,
  requestSaveToLocal,
  persistNow,
} from './common/storageHelper';
import {
  mergeModuleState,
  setItemOptionMode,
  setItemOptionState,
  setItemWritingText,
  setNotesText,
  setItemMarkForReview,
  setSectionValue,
  setItemTotalHintsViewed,
} from './examManagementHelper';
import {
  setEntireInstanceDirty,
  getSection,
  setInstanceChanged,
  removeInstanceFromRepo,
} from './common/instanceHelper';
import { persistenceState } from './common/persistenceState';
import { getInstanceById, getInstanceRepo } from './common/repoHelper';
import { refreshSummary } from './common/summaryHelper';
import { getTechnicalPauseData } from '../technicalPauseHelper';
/**
 * When we retrieve an existing instance from the server, set the state
 * here so we can have the latest as it gets updated. We need this because
 * in Azure, the state is replaced, not merged. So we need to make sure we
 * have the latest state on the client.
 * @param {string} testInstanceId the test instance id
 * @param {object} state the state returned by the server
 */
export function populateStateFromServer(
  testInstanceId,
  state,
  module,
  summary,
  launchDetails,
  testInstanceRemoteStatus,
) {
  const instance = getInstanceById(testInstanceId);
  if (!isNullOrUndefined(state)) {
    if (
      isNullOrUndefined(instance.state) ||
      isNullOrUndefined(instance.state.lastChangedTime)
    ) {
      instance.state = state;
    } else if (instance.state.lastChangedTime < state.lastChangedTime) {
      instance.state = mergeModuleState(instance.state, state, true, module);
      setEntireInstanceDirty(instance);
      requestSaveToLocal();
      instance.state.lastChangedTime = Date.now();
    } else {
      instance.state = mergeModuleState(state, instance.state, true, module);
      setEntireInstanceDirty(instance);
      requestSaveToLocal();
      instance.state.lastChangedTime = Date.now();
    }
  }

  if (summary) {
    instance.summary = summary;
  }
  if (launchDetails) {
    instance.launchDetails = launchDetails;
  }
  if (testInstanceRemoteStatus) {
    instance.testInstanceRemoteStatus = testInstanceRemoteStatus;
  }
}

/**
 * When a saga call returns fail, clean up the repo if we get a 404.
 * A 404 means the test instance no longer exists and we need to clean up.
 * @param {string} testInstanceId the test instance id
 * @param {object} error the error object
 */
export function sendStateToServerError(testInstanceId, error) {
  logException(error, 'sendStateToServerError');
  try {
    if (
      testInstanceId !== persistenceState.testInstanceId &&
      !isNullOrUndefined(error.response) &&
      error.response.status === 404
    ) {
      removeInstanceFromRepo(testInstanceId);
      persistenceState.secondsBetweenRequests = 0;
      requestSaveToLocal();
    }
    // Error code 409 defines that the data is already updated on server
    // and the test is completed
    if (error.response.status === 409) {
      // remove api calls from request queue for this test instance
      remove(persistenceState.requestQueue, id => id === testInstanceId);
      removeInstanceFromRepo(testInstanceId);
      persistenceState.secondsBetweenRequests = 0;
      requestSaveToLocal();
    }
    if (persistenceState?.module?.options?.exitOnConnectionFailure) {
      persistenceState.secondsBetweenRequests = parseInt(
        config.REACT_APP_PERSIST_SECONDS_EXAM_RETRY,
        10,
      );
      checkForDisconnectedTimeout();
    } else {
      resetSuccessState();
    }
  } catch (ex) {
    logException(ex, 'sendStateToServerError(2)');
  } finally {
    persistenceState.requestInProgress = false;
  }
}

function checkForDisconnectedTimeout() {
  if (persistenceState?.module?.options?.exitOnConnectionFailure) {
    const timeout =
      parseInt(config.REACT_APP_EXAM_DETECT_DISCONNECTED_SECONDS, 10) * 1000;
    const instanceRepo = getInstanceRepo();
    if (
      Date.now() - persistenceState.lastSuccessfulSend > timeout &&
      !isNullOrUndefined(instanceRepo) &&
      !isNullOrUndefined(instanceRepo.state) &&
      !instanceRepo.state.isCompleted
    ) {
      resetSuccessState();
      persistenceState.exitFlex();
    }
  }
}

function resetSuccessState() {
  persistenceState.lastSuccessfulSend = Date.now();
}

/**
 * When a saga call returns success, update the repo. If this is for
 * the active instance, mark it as unchanged. If it is not the active
 * instance, we have sent up the most recent state, so we can remove it
 * from local storage and not worry about it for now.
 * @param {string} testInstanceId the test instance id
 */
export function sendStateToServerSuccess(testInstanceId) {
  try {
    resetSuccessState();
    const instance = getInstanceById(testInstanceId);

    // avoid immediate call to another instance API even
    // when last call was for current instance
    instance.serverNeedsLatest = false;
    persistenceState.secondsBetweenRequests = parseInt(
      config.REACT_APP_PERSIST_SECONDS_BETWEEN_REQUESTS,
      10,
    );
    requestSaveToLocal();
  } catch (ex) {
    logException(ex, 'sendStateToServerSuccess');
  } finally {
    persistenceState.requestInProgress = false;
  }
}
/**
 * Called on an interval to send current state to server and
 * save current state to local storage
 */
export async function sendState() {
  try {
    if (persistenceState.isPaused) return;
    if (!persistenceState.initialized) {
      return;
    }
    await checkForDuplicates();
    checkForStaleRequest();
    checkForDisconnectedTimeout();
    await checkForSaveToDb();
    if (
      !isNullOrUndefined(persistenceState.putInstanceStateCall) &&
      !persistenceState.requestInProgress
    ) {
      if (
        (Date.now() - persistenceState.lastPersist) / 1000 >
        persistenceState.secondsBetweenRequests
      ) {
        persistenceState.secondsBetweenRequests = parseInt(
          config.REACT_APP_PERSIST_SECONDS_BETWEEN_REQUESTS,
          10,
        );
        requestSaveToLocal();
        fillRequestQueue();
        if (
          persistenceState.requestQueue.length > 0 &&
          window.navigator.onLine
        ) {
          const instanceId = persistenceState.requestQueue[0];
          sendStateNow(instanceId);
          remove(persistenceState.requestQueue, id => id === instanceId);
        } else {
          persistenceState.lastSuccessfulSend = new Date();
          persistenceState.lastPersist = Date.now();
        }
      }
    }
  } catch (ex) {
    logException(ex, 'sendState');
  }
}

export function sendStateNow(instanceId) {
  if (persistenceState.isPaused) return;

  if (persistenceState.completedTestIds?.includes(instanceId)) return;

  persistenceState.isCurrentTestCompleted = false;

  const instance = getInstanceById(instanceId, false);

  if (
    !isNullOrUndefined(instance) &&
    !persistenceState.requestInProgress &&
    instance.summary &&
    instance.state
  ) {
    persistenceState.requestInProgress = true;
    persistenceState.requestTime = Date.now();
    persistenceState.lastPersist = Date.now();
    persistenceState.putInstanceStateCall({
      userId: getUserId(),
      testInstanceId: instance.testInstanceId,
      state: instance.state,
      summary: instance.summary,
      launchDetails: instance.launchDetails,
      module: instance.module,
    });
  }
}
/**
 * If for some reason we don't get a response to a request, unblock the
 * send of subsequent requests after specified time
 */
function checkForStaleRequest() {
  if (persistenceState.requestInProgress) {
    const timeSinceLastRequest = Date.now() - persistenceState.requestTime;
    if (
      timeSinceLastRequest >=
      parseInt(config.REACT_APP_UNBLOCK_PERSISTENCE_SECONDS, 10) * 1000
    ) {
      persistenceState.requestInProgress = false;
    }
  }
  if (Date.now() - persistenceState.lastPersist < 0) {
    persistenceState.lastPersist = Date.now();
  }
}

/**
 * Gets the initial state for creating an instance
 * @param {ExamMode} examMode the exam mode
 * @param {number} customTime the custom exam time mode
 */
export function getInitialState(examMode, customTime, moduleType) {
  const now = currentTimeAsISO();
  const state = {
    lastUpdateTime: now,
    startTime: now,
    lastChangedTime: Date.now(),
    browser: getBrowserName(),
  };
  if (!isNullOrUndefined(examMode)) {
    state.examMode = examMode;
  }
  if (!isNullOrUndefined(customTime)) {
    state.customTime = customTime;
  }
  if (!isNullOrUndefined(moduleType)) {
    state.moduleType = moduleType;
  }
  return state;
}
/**
 * Adds or replaces a state in the state change repository
 * @param {string} actionType the type of action that occurred
 * @param {string} sectionId the section id for the element that changed
 * @param {object} payload the state data to save
 */
export function logChange(actionType, sectionId, payload) {
  let section = null;
  let changed = false;
  if (!isNullOrUndefined(sectionId)) {
    section = getSection(sectionId);
    if (!isNullOrUndefined(section) && section.isCompleted)
      return persistenceState.repo;
  }
  switch (actionType) {
    case constants.CHANGE_FONTSIZE:
    case constants.CHANGE_LINEHEIGHT:
    case constants.CHANGE_HIGHLIGHT:
      return persistenceState.repo;
    case constants.CHANGE_SELECTION:
      changed = setSectionValue(sectionId, 'currentItemIndex', payload);
      break;
    case constants.CHANGE_TIME_REMAINING:
      {
        const tr = Math.trunc(payload / 1000) * 1000;
        changed = setSectionValue(sectionId, 'timeRemaining', tr);
      }
      break;
    case constants.CHANGE_ANALYSIS_TIME_REMAINING:
      {
        const tr = Math.trunc(payload / 1000) * 1000;
        changed = setSectionValue(sectionId, 'analysisTimeRemaining', tr);
      }
      break;
    case constants.CHANGE_ELAPSED_TIME:
      {
        const tr = Math.trunc(payload / 1000) * 1000;
        changed = setSectionValue(sectionId, 'elapsedTime', tr);
      }
      break;
    case constants.CHANGE_INTERMISSION_TIME_REMAINING:
      {
        const tr = Math.trunc(payload / 1000) * 1000;
        changed = setSectionValue(sectionId, 'intermissionTimeRemaining', tr);
      }
      break;
    case constants.CHANGE_BREAK_TIME_REMAINING:
      {
        const tr = Math.trunc(payload / 1000) * 1000;
        changed = setSectionValue(sectionId, 'breakTimeRemaining', tr);
      }
      break;
    case constants.SET_TIME_VISIBILITY:
      break;
    case constants.CHANGE_OPTION_MODE:
      setItemOptionMode(
        sectionId,
        payload.itemNumber,
        payload.optionLetter,
        payload.optionMode,
      );
      changed = true;
      break;
    case constants.CHANGE_OPTION_STATE:
      setItemOptionState(sectionId, payload.itemNumber, payload.answerOptions);
      changed = true;
      break;
    case constants.CHANGE_WRITING_TEXT:
      setItemWritingText(sectionId, payload.itemNumber, payload.writingText);
      changed = true;
      break;
    case constants.UPDATE_NOTES_TEXT:
      setNotesText(sectionId, payload);
      changed = true;
      break;
    case constants.MARK_FOR_REVIEW:
      setItemMarkForReview(
        sectionId,
        payload.itemNumber,
        payload.markForReview,
      );
      changed = true;
      break;
    case constants.ITEM_VIEW_HINT:
      setItemTotalHintsViewed(
        sectionId,
        payload.itemNumber,
        payload.totalHintsViewed,
      );
      changed = true;
      break;
    case constants.CHANGE_READY_TO_CHECKIN_STATUS:
      changed = setSectionValue(sectionId, 'isReadyToCheckin', payload);
      break;
    default:
      return persistenceState.repo;
  }

  if (changed) {
    setInstanceChanged(section, actionType);
    requestSaveToLocal();
  }
  return persistenceState.repo;
}

/**
 * If there are entries in the request queue, just return.
 * If it is empty, look for any changed instances and add them
 * to the queue
 */
export function fillRequestQueue() {
  // if we have pending tansactions get done with those first
  if (persistenceState.requestQueue.length > 0) {
    return persistenceState.requestQueue;
  }
  // find the instances that have changed and add their ids to the request queue
  let i = 0;
  for (i = 0; i < persistenceState.repo.instances.length; i += 1) {
    const instance = persistenceState.repo.instances[i];
    if (
      instance.serverNeedsLatest &&
      instance.testInstanceId !== null &&
      !persistenceState.completedTestIds?.includes(instance.testInstanceId)
    ) {
      persistenceState.requestQueue.push(instance.testInstanceId);
    }
  }
  // always put the current instance at the end of the queue
  // so that all other requests go first
  const instanceRepo = getInstanceRepo();
  if (
    instanceRepo !== null &&
    instanceRepo.serverNeedsLatest &&
    instanceRepo.testInstanceId !== null
  ) {
    persistenceState.requestQueue.push(instanceRepo.testInstanceId);
  }
  return persistenceState.requestQueue;
}
/**
 * Sets the isContentExposed flag in state and summary in the instance to true
 * @returns {void}
 */
export function setInstanceExposed() {
  const instance = getInstanceRepo();
  if (instance && instance.state) {
    // only set exposed one time and once set do nothing else
    if (
      instance.state.isContentExposed === undefined ||
      instance.state.isContentExposed === false
    ) {
      instance.state.isContentExposed = true;
    } else {
      return;
    }
  }

  setInstanceChanged(null, 'isContentExposed');
  refreshSummary();
  persistNow();
}

export function addTechnicalPauseInitiated() {
  const instanceRepo = getInstanceRepo();

  if (!isNullOrUndefined(instanceRepo)) {
    const { state } = instanceRepo;

    const data = getTechnicalPauseData();

    if (!isNullOrUndefined(state)) {
      if (isNullOrUndefined(state.technicalPauseInitiated)) {
        state.technicalPauseInitiated = [];
      }
      state.technicalPauseInitiated.push(data);
    }

    setInstanceChanged(null, 'technicalPauseInitiated');
    refreshSummary();
    persistNow();
  }
}

export function addEarlySubmitInitiated(data) {
  const instanceRepo = getInstanceRepo();

  if (!isNullOrUndefined(instanceRepo)) {
    const { state } = instanceRepo;

    if (!isNullOrUndefined(state)) {
      if (isNullOrUndefined(state.submitEarlyInitiated)) {
        state.submitEarlyInitiated = [];
      }
      state.submitEarlyInitiated.push(data);
    }

    setInstanceChanged(null, 'submitEarlyInitiated');
    refreshSummary();
    persistNow();
  }
}
