/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { intlShape, injectIntl } from 'react-intl';
import injectReducer from 'utils/injectReducer';
import './MasterPageStyles.css';
import * as progressTimerHelper from 'helpers/progressTimerHelper';

import { ModalBody, Button } from 'reactstrap';
import { ExamMode } from 'enumerations/ExamMode';
import { HighlightType } from 'enumerations/HighlightType';
import * as moduleHelper from 'helpers/moduleHelper';
import appInitPageReducer from 'domains/session/reducer';
import {
  isAppReadySelector,
  userDetailsSelector,
} from 'domains/session/selectors';
import { SESSION_REDUX_NAME } from 'domains/session/hooks';
import isNullOrUndefined from '../../utils/isNullOrUndefined';
import { routerSelector } from '../App/selectors';
import { AccessibleModal } from '../../components/AccessibleModal';
import {
  DIRECTION_PATH,
  PASSAGE_PATH,
  EXAM_PASSAGE_PATH,
  QUESTION_PATH,
  TUTORIALS_PATH,
  TUTORIAL_PATH,
  TEST_COMPLETE_PATH,
  EXAM_TEST_COMPLETE_PATH,
  TEST_COMPLETE_FLEX_PATH,
  TEST_REVIEW_PATH,
  EXAM_TEST_REVIEW_PATH,
  HISTORY_PATH,
  MODULE_PATH,
  HOME_PATH,
  UPGRADE_PATH,
  LSAT_FLEX_PATH,
  CERTIFYING_STATEMENT_PATH,
  CONNECTIVITY_ERROR_PATH,
  EXAM_START_PATH,
  INTERMISSION_PATH,
  PLUS_PROGRAM_REGISTRATION,
  PLUS_PROGRAM_REGISTRATION_COMPLETE,
  STARTUP_PATH,
  EVENT_JOIN_PATH,
  EXAM_MODULE_PATH,
  EXAM_DIRECTION_PATH,
  EXAM_QUESTION_PATH,
  EXAM_PAUSE_PATH,
  LIBRARY_PATH,
  DRILLSET_PATH,
  ADDITIONAL_PRACTICE_TEST_PATH,
  API_ERROR_PATH,
  OFFLINE_ERROR_PATH,
} from '../App/constants';
import ToolbarButton from '../../components/ToolbarButton/index';
import ReloadingLink from '../../components/ReloadingLink/index';
import { getQueryVariable } from '../../helpers/historyHelper';
import {
  DEFAULT_BREADCRUMB_ITEMS,
  DEFAULT_DRILLSET_BREADCRUMB_ITEMS,
  DEFAULT_TUTORIAL_BREADCRUMB_ITEMS,
  GET_ISBREADCRUMBSEANBLED,
  TITLE_TEST_PREP_HOME,
  TITLE_TEST_PREP_TUTORIAL,
  TITLE_TEST_PREP_LIBRARY,
  TITLE_BUY_PP,
  TITLE_TEST_FLEX_EXAM,
  DEFAULT_ADDITIONAL_PRACTICE_SET_BREADCRUMB_ITEMS,
} from './constants';
import history from '../../utils/history';
import {
  showAnswer,
  setSectionComplete,
  sectionUnLoadAction,
  onPauseSection,
  setPassageViewAction,
  pauseFlexExamAction,
  changeHighlightAction,
  setIsWritingSection,
} from '../DirectionsPage/actions';
import { breadCrumbSectionSelector, showSideNavSelector } from './selectors';
import {
  selectedInstanceSelector,
  instancesSelector,
  sectionSelector,
  showAnswerSelector,
  currentItemSelector,
  pauseSectionSelector,
} from '../DirectionsPage/selectors';
import { historyModuleSelector } from '../HistoryPage/selectors';
import { config } from '../../config';
import messages from './messages';
import { setCurrentInstance } from '../../helpers/persistence/repoManagementHelper';
import { logModuleComplete } from '../../helpers/persistence/examManagementHelper';
import { persistNow } from '../../helpers/persistence/common/storageHelper';
import {
  putInstanceStateAction,
  getFlexExamListErrorAction,
} from '../TestSelectPage/actions';
import { displaySideNavBar, updateWindowSize } from './actions';
import { LSAC_FLEX_START_PATH } from '../TestSelectPage/constants';
import directionsPageReducer from '../DirectionsPage/reducer';
import testSelectPageReducer from '../TestSelectPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';
import {
  SECTION_TIMER_NAME,
  UNLIMITED_TIME_NAME,
  ADDITIONAL_PRACTICE_TIMER_NAME,
} from '../../components/MetisProgress/constants';
import { FLEX_BREAK_TIMER_NAME } from '../../components/DirectionsPanel/constants';
import DropdownMenu, { DropdownItem } from '../../components/Menu';
import PageHeader from '../../components/PageHeader';
import SidebarNav from './SidebarNav';
import {
  getSectionOptions,
  getCurrentExam,
  isPrometrics,
} from '../../helpers/examHelper';
import {
  itemsSelector,
  flexExamsSelector,
  testInstanceRemoteStatusSelector,
} from '../TestSelectPage/selectors';
import injectSaga from '../../utils/injectSaga';
import { addTechnicalPauseInitiated } from '../../helpers/persistence/instanceStateManagementHelper';
import { isRealExam } from '../../helpers/moduleHelper';
import { PATHS_NOT_ALLOWED_FOR_HEADER } from '../../components/PageHeader/constant';
import { ModuleType } from '../../enumerations/ModuleType';
import { EXAM_PATH_PREFIX } from '../../helpers/constants';

/**
 * This is the MasterPage component for v1.
 * @param {string} history - Shows the current route, and related properties.
 * @param {boolean} IsBreadcrumbsEnabled - Show's the Breadcrumb UI if true.
 * @example <MasterPage IsBreadcrumbsEnabled="true">HTML Content</MasterPage>
 */
export class MasterPage extends React.Component {
  navigation = null;

  setCurrentBaseNavHref = '/';

  sidebarId = 'masterPageSidebar';

  sidebarCloseButtonId = 'sidebarCloseButton';

  sidebarCloseButtonIdRebrand = 'sidebarCloseButton-rebrand';

  sectionPaths = [
    EXAM_DIRECTION_PATH,
    DIRECTION_PATH,
    QUESTION_PATH,
    EXAM_QUESTION_PATH,
    PASSAGE_PATH,
    EXAM_PASSAGE_PATH,
  ];

  neverShowAnsModalCheckBoxId = 'chkNeverShowAnswerPopup';

  constructor(props) {
    super(props);
    this.showAnswer = this.showAnswer.bind(this);
    this.getAnswerText = this.getAnswerText.bind(this);
    this.answerButtonText = 'Show Answer';
    this.onCompleteSection = this.onCompleteSection.bind(this);
    this.onNextSection = this.onNextSection.bind(this);
    this.onPauseSection = this.onPauseSection.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.setDocumentTitleByPath = this.setDocumentTitleByPath.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.hideSidebar = this.hideSidebar.bind(this);
    this.pausePopupMessage = null;
    this.notice = null;
    this.previousPagePaused = null;
    this.previousPausedOnUrl = null;
    this.isNavigatingFromSection = false;
    this.isExitingFlex = false;
    this.menuTimeOutId = null;
    this.appInitRedirectInProgress = false;
    this.searchRef = React.createRef();
    this.offlineTimer = null;
    this.state = {
      dropdownOpen: false,
      size: { width: 0, height: 0 },
      neverShowAgain: false,
      popUpOpen: false,
      isSectionPaused: false,
      isRedirectedToPlusProgram: false,
    };

    document.body.addEventListener('mousedown', () => {
      document.body.classList.remove('keyboard-focus');
      document.body.classList.add('mouse-focus');
    });

    document.body.addEventListener('keydown', e => {
      document.body.classList.remove('mouse-focus');
      document.body.classList.add('keyboard-focus');
    });

    window.addEventListener('online', () => {
      this.handleNetworkStatus();
    });
    window.addEventListener('offline', () => {
      this.handleNetworkStatus();
    });

    this.previousPathWhenPaused = null;
  }

  getTimerName() {
    if (this.isDrillSet()) return UNLIMITED_TIME_NAME;
    if (this.isAdditionalPracticeSet()) return ADDITIONAL_PRACTICE_TIMER_NAME;
    return SECTION_TIMER_NAME;
  }

  handleNetworkStatus() {
    // check if current exam is a prometrics
    const isPrometricsExam =
      this.props.exams?.length > 0 ? isPrometrics(this.props.exams[0]) : false;

    // if the user is taking real prometric exam and network is offline,
    // wait for 2 mins (configurable) and show error screen
    if (
      !navigator.onLine &&
      window.location.pathname.includes(EXAM_PATH_PREFIX) &&
      isPrometricsExam
    ) {
      this.offlineTimer = setTimeout(
        () =>
          history.push(OFFLINE_ERROR_PATH, {
            handlingFailedAPI: true,
            isRealExam: true,
          }),
        config.REACT_APP_OFFLINE_MESSAGE_DELAY_MINUTES * 60000,
      );
    } else if (navigator.onLine) {
      // if the timeout to show error screen is still in progress, clear the timeout
      if (this.offlineTimer) clearTimeout(this.offlineTimer);

      // if the user is taking real exam and network is back online,
      // navigate to the certifying screen to resume test
      if (window.location.pathname.includes(OFFLINE_ERROR_PATH))
        history.push(CERTIFYING_STATEMENT_PATH);
    }
  }

  onCloseShowAnswerPopup = () => {
    this.setState({ popUpOpen: false });
  };

  onClosePauseSectionPopup = () => {
    this.setState({ isSectionPaused: false });

    const neverShowPauseCheckBox = document.getElementById(
      'chkNeverShowPausePopup',
    );

    const { state } = this.props.selectedInstance;
    const hasToStopShowingPopup = !isNullOrUndefined(neverShowPauseCheckBox)
      ? neverShowPauseCheckBox.checked === true
      : state?.shouldNeverShowPausePopup &&
        state?.pausedSectionId === this.props.section.formId;

    setTimeout(() => {
      this.props.onSectionPause(
        false,
        this.previousPagePaused,
        hasToStopShowingPopup,
      );
    }, 10);

    if (this.isNavigatingFromSection && this.state.isSectionPaused) {
      history.push(this.previousPausedOnUrl);
    }
  };

  togglePopUp() {
    this.setState(previousState => ({
      ...previousState,
      dropdownOpen: !previousState.popUpOpen,
    }));
  }

  onContinueShowAnswerPopUp = () => {
    this.setState({ popUpOpen: false });
    let showAnswerMessage = '';
    if (this.props.section.items && !this.props.hasToShowAnswer) {
      const itemIndex = this.props.section.currentItemIndex;
      const correctAnswerOption = this.props.section.items[itemIndex]
        .correctAnswer;
      showAnswerMessage = `${this.props.intl.formatMessage(
        { ...messages.showAnswerLabel },
        {
          correctAnswerOption,
        },
      )}`;
    }
    const showAnswerSpan = document.getElementById('onShowAnswer');
    if (!isNullOrUndefined(showAnswerSpan)) {
      showAnswerSpan.textContent = showAnswerMessage;
    }

    this.props.showAnswer(!this.props.hasToShowAnswer);
    // eslint-disable-next-line no-restricted-globals
    let target = event.target ? event.target : event.srcElement;
    if (target && target.nodeName.toLowerCase() === 'button') {
      target.style.display = 'none';
    }
  };

  onCheckNeverShowAgain = () => {
    const neverShowAgainCheckBox = document.getElementById(
      this.neverShowAnsModalCheckBoxId,
    );
    if (!isNullOrUndefined(neverShowAgainCheckBox)) {
      this.setState({ neverShowAgain: neverShowAgainCheckBox.checked });
    }
  };

  isCurrentAndNextSectionsCompleted() {
    if (
      !isNullOrUndefined(this.props.selectedInstance) &&
      !isNullOrUndefined(this.props.breadCrumbSection) &&
      this.props.breadCrumbSection.isCompleteSectionVisible === true
    ) {
      const { module } = this.props.selectedInstance;

      if (!isNullOrUndefined(module)) {
        let nextSectionIndex = 0;

        const currentSectionIndex = module.sections.findIndex(
          s => s.formId === this.props.section.formId,
        );
        if (currentSectionIndex !== module.sections.length - 1) {
          nextSectionIndex = currentSectionIndex + 1;
          return (
            module.sections[currentSectionIndex].isCompleted === true &&
            module.sections[nextSectionIndex].isCompleted === true
          );
        }
        return false;
      }
    }
    return false;
  }

  hasAnySectionPaused(testInstanceId) {
    const { module } = this.props.instances.find(
      val => val.testInstanceId === testInstanceId,
    );
    if (!isNullOrUndefined(module)) {
      const pausedSections = module.sections.find(section => section.isPaused);
      return !isNullOrUndefined(pausedSections);
    }
    return false;
  }

  onPauseSection(isRedirectingFromSection = false) {
    if (
      moduleHelper.disableBackButton(this.props.selectedInstance) &&
      isRedirectingFromSection === true
    ) {
      window.setTimeout(() => {
        if (history.action !== 'POP' && !this.isExitingFlex) {
          history.push(this.previousPausedOnUrl);
        }
        this.isExitingFlex = false;
      }, 1);
      return;
    }
    if (isRedirectingFromSection === true) {
      this.pausePopupMessage = `${this.props.intl.formatMessage({
        ...messages.pauseSectionPopupNavigate,
      })}`;
    } else {
      this.pausePopupMessage = `${this.props.intl.formatMessage({
        ...messages.pauseSectionPopup,
      })}`;
    }
    if (isRedirectingFromSection === true) {
      this.notice = `${this.props.intl.formatMessage({
        ...messages.notice,
      })}`;
    }

    const { state, module } = this.props.selectedInstance;
    if (
      !this.state.isSectionPaused &&
      !module?.isNeverShowPauseMessage &&
      !state?.shouldNeverShowPausePopup
    ) {
      this.setState({ isSectionPaused: true });
    } else {
      const isSectionPaused = !isNullOrUndefined(this.props.section)
        ? !this.props.pauseButton
        : false;
      const url = this.props.history.pathname;
      const navItems = url.split('/');
      let pagePaused = navItems.length >= 1 ? navItems[1] : null;
      pagePaused =
        this.isNavigatingFromSection &&
        !isNullOrUndefined(this.previousPagePaused)
          ? this.previousPagePaused
          : pagePaused;
      const neverShowPauseCheckBox = document.getElementById(
        'chkNeverShowPausePopup',
      );

      const hasToStopShowingPopup = !isNullOrUndefined(neverShowPauseCheckBox)
        ? neverShowPauseCheckBox.checked === true
        : state?.shouldNeverShowPausePopup &&
          state?.pausedSectionId === this.props.section.formId;

      this.props.onSectionPause(
        isSectionPaused,
        pagePaused,
        hasToStopShowingPopup,
      );
      const isInTest = ['section', 'question', 'module', 'passage'].includes(
        navItems[1],
      );
      if (isSectionPaused) {
        if (navItems.length >= 3 && isInTest) {
          if (this.isDrillSet()) {
            // redirect to drillset tab only on athenanext
            let drillSetPath =
              config.REACT_APP_IS_LIBRARY_NEXT_REDIRECT_ENABLED === 'true'
                ? DRILLSET_PATH
                : LIBRARY_PATH;
            history.push(drillSetPath);
          } else if (this.isAdditionalPracticeSet()) {
            // redirect to additional practice tab only on athenanext
            let additionalPracticeSet =
              config.REACT_APP_IS_LIBRARY_NEXT_REDIRECT_ENABLED === 'true'
                ? ADDITIONAL_PRACTICE_TEST_PATH
                : LIBRARY_PATH;
            history.push(additionalPracticeSet);
          } else {
            history.push(
              isRealExam(this.props.selectedInstance?.module)
                ? `${EXAM_MODULE_PATH}/${navItems[2]}`
                : `${MODULE_PATH}/${navItems[2]}`,
            );
          }
        }
      }
      this.setState({ isSectionPaused: false });
      persistNow();
      this.isNavigatingFromSection = false;
    }
  }

  getPauseText() {
    if (this.isDrillSet() || this.isAdditionalPracticeSet()) {
      return 'CLOSE';
    }
    return 'Pause Section';
  }

  onCompleteSection() {
    const isSectionCompleted = !isNullOrUndefined(this.props.section)
      ? this.props.section.isCompleted
      : false;
    const url = this.props.history.pathname;
    const navItems = url.split('/');
    if (isSectionCompleted === false) {
      if (navItems.length >= 3) {
        // mark section as completed
        this.props.onSectionComplete();
        let hasAllSectionsCompleted = false;
        if (
          !isNullOrUndefined(this.props.selectedInstance) &&
          !isNullOrUndefined(this.props.breadCrumbSection) &&
          this.props.breadCrumbSection.isCompleteSectionVisible === true
        ) {
          const { module } = this.props.instances.find(
            val =>
              val.testInstanceId === this.props.selectedInstance.testInstanceId,
          );
          hasAllSectionsCompleted = moduleHelper.hasAllSectionsCompleted(
            module,
          );
        }
        if (hasAllSectionsCompleted) {
          // if this is a flex test navigate to the correct page
          if (moduleHelper.isLSATFlexInstance(this.props.selectedInstance)) {
            navItems[1] = 'exam/examTestComplete';
          } else {
            navItems[1] = 'testComplete';
          }
          navItems.pop();
          logModuleComplete();
        } else if (
          this.props.section?.options?.nextBreakType === 'intermission'
        ) {
          // eslint-disable-next-line prettier/prettier
          navItems[1] = 'intermission';
        } else {
          navItems[1] = 'testReview';
        }
        const newUrl = navItems.join('/');
        history.push(newUrl);
      }
    } else {
      history.push(`${MODULE_PATH}/${navItems[2]}`);
    }
    this.toggleDropdown();
    persistNow();
  }

  onNextSection() {
    if (
      !isNullOrUndefined(this.props.selectedInstance) &&
      !isNullOrUndefined(this.props.breadCrumbSection) &&
      !isNullOrUndefined(this.props.section) &&
      this.props.breadCrumbSection.isCompleteSectionVisible === true
    ) {
      const { module } = this.props.selectedInstance;
      let nextSectionTitle = module.sections[0].title;

      const currentSectionIndex = module.sections.findIndex(
        s => s.formId === this.props.section.formId,
      );
      if (currentSectionIndex !== module.sections.length - 1) {
        nextSectionTitle = module.sections[currentSectionIndex + 1].title;
      }
      this.props.onSectionUnLoad();
      const REAL_EXAM_PATH = `/exam/question/${
        this.props.selectedInstance.testInstanceId
      }/${nextSectionTitle}`;
      const TEST_EXAM_PATH = `/question/${
        this.props.selectedInstance.testInstanceId
      }/${nextSectionTitle}`;
      history.push(
        this.props.selectedInstance?.module?.options?.isRealExamMode
          ? REAL_EXAM_PATH
          : TEST_EXAM_PATH,
      );
    }
  }

  updateDimensions = () => {
    if (
      this.state.size.width !== window.innerWidth ||
      this.state.size.height !== window.innerHeight
    ) {
      this.setState({
        size: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      });
      this.props.onUpdateWindowSize(this.state.size);
    }
  };

  handleClick = evt => {
    if (
      evt.target.id === 'Certhamburger' ||
      evt.target.id === this.sidebarCloseButtonId ||
      evt.target.id === this.sidebarCloseButtonIdRebrand ||
      evt.target.className === 'hamburgerButton hamburgerImgButton' ||
      evt.target.id === 'Homehamburger'
    ) {
      return;
    }

    const flyoutElement = document.getElementById(this.sidebarId);
    // if (flyoutElement.className !== 'activeNav') return;
    let targetElement = evt.target; // clicked element

    do {
      if (
        targetElement === flyoutElement ||
        targetElement.className === 'modal-body'
      ) {
        // This is a click inside. Do nothing, just return.
        return;
      }
      // Go up the DOM
      targetElement = targetElement.parentNode;
    } while (targetElement);

    // This is a click outside.
    this.hideSidebar();
  };

  setDocumentTitleByPath(pathName) {
    let title = TITLE_TEST_PREP_HOME;
    if (!isNullOrUndefined(pathName)) {
      const libraryPaths = [
        TEST_COMPLETE_PATH,
        EXAM_TEST_COMPLETE_PATH,
        TEST_REVIEW_PATH,
        EXAM_TEST_REVIEW_PATH,
        HISTORY_PATH,
        HOME_PATH,
      ];
      const isLibraryPath = libraryPaths.some(path =>
        path === HOME_PATH
          ? pathName === path
          : pathName.toLowerCase().startsWith(path.toLowerCase()),
      );

      const tutorialPaths = [TUTORIALS_PATH, TUTORIAL_PATH];
      const isTutoritalPath = tutorialPaths.some(path =>
        pathName.toLowerCase().startsWith(path.toLowerCase()),
      );

      const flexPaths = [
        LSAT_FLEX_PATH,
        LSAC_FLEX_START_PATH,
        CERTIFYING_STATEMENT_PATH,
        TEST_COMPLETE_FLEX_PATH,
      ];

      const lsatWritingPaths = [EXAM_START_PATH];
      const isFlexPath = flexPaths.some(path =>
        pathName.toLowerCase().startsWith(path.toLowerCase()),
      );
      const isBarPath = lsatWritingPaths.some(path =>
        pathName.toLowerCase().startsWith(path.toLowerCase()),
      );
      const isUpgradePath = pathName
        .toLocaleLowerCase()
        .startsWith(UPGRADE_PATH.toLowerCase());

      if (isTutoritalPath) {
        title = TITLE_TEST_PREP_TUTORIAL;
      } else if (isLibraryPath) {
        title = TITLE_TEST_PREP_LIBRARY;
      } else if (isUpgradePath) {
        title = TITLE_BUY_PP;
      } else if (isFlexPath) {
        title = TITLE_TEST_FLEX_EXAM;
      } else if (isBarPath) {
        title = 'LSAC - LSAT Writing';
      }
    }
  }

  componentDidMount() {
    this.appInitRedirectIfNeeded(this.props.isAppReady);
    window.addEventListener('resize', this.updateDimensions);
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    document.removeEventListener('click', this.handleClick);

    window.removeEventListener('online');
    window.removeEventListener('offline');
  }

  componentDidUpdate(previousProps, prevState) {
    this.appInitRedirectIfNeeded(this.props.isAppReady);
    this.addSkipToMainLink();
    if (!this.isSectionPage()) {
      const shouldClear =
        !moduleHelper.disableBackButton(this.props.selectedInstance) ||
        !(history.action === 'POP');
      if (shouldClear) {
        progressTimerHelper.resetTimer(this.getTimerName());
      }
    }
    if (!this.isModulePage() && history.action !== 'POP') {
      progressTimerHelper.resetTimer(FLEX_BREAK_TIMER_NAME);
    }
    if (
      document.body.clientWidth <=
      parseInt(config.REACT_APP_APOLLO_TABLET_WIDTH_BREAKPOINT, 10)
    ) {
      const nav = document.getElementById(this.sidebarId);
      if (nav !== null) {
        nav.setAttribute('aria-hidden', 'true');
      }
    }
    const previousPathName = previousProps.history.pathname.split('/');
    const currentPathName = this.props.history.pathname.split('/');

    const flexPaths = [
      LSAT_FLEX_PATH,
      LSAC_FLEX_START_PATH,
      CERTIFYING_STATEMENT_PATH,
      TEST_COMPLETE_FLEX_PATH,
      DIRECTION_PATH,
      EXAM_DIRECTION_PATH,
      QUESTION_PATH,
      EXAM_QUESTION_PATH,
      PASSAGE_PATH,
      EXAM_PASSAGE_PATH,
      MODULE_PATH,
      EXAM_MODULE_PATH,
      TEST_REVIEW_PATH,
      EXAM_TEST_REVIEW_PATH,
      CONNECTIVITY_ERROR_PATH,
    ];

    const isNavigatingAwayFromSection =
      !isNullOrUndefined(previousPathName) &&
      !isNullOrUndefined(currentPathName) &&
      currentPathName[1].toLocaleLowerCase() !==
        previousPathName[1].toLocaleLowerCase() &&
      previousPathName.length > 0 &&
      currentPathName.length > 0 &&
      this.sectionPaths.some(
        path => path.toLowerCase() === `/${currentPathName[1]}`.toLowerCase(),
      ) === false &&
      this.sectionPaths.some(
        path => path.toLowerCase() === `/${previousPathName[1]}`.toLowerCase(),
      ) === true;

    const isNavigatingAwayFromFlex =
      !isNullOrUndefined(previousPathName) &&
      !isNullOrUndefined(currentPathName) &&
      currentPathName[1].toLocaleLowerCase() !==
        previousPathName[1].toLocaleLowerCase() &&
      previousPathName.length >= 1 &&
      currentPathName.length >= 1 &&
      flexPaths.some(
        path => path.toLowerCase() === `/${currentPathName[1]}`.toLowerCase(),
      ) === false &&
      flexPaths.some(
        path => path.toLowerCase() === `/${previousPathName[1]}`.toLowerCase(),
      ) === true;

    if (isNavigatingAwayFromSection) {
      this.props.onChangeHighlight(HighlightType.None);
      this.props.onSetIsWritingSection(false);
    }

    if (isNavigatingAwayFromFlex) {
      this.props.ongetFlexExamListError();
    }

    let isFullScreen = moduleHelper.showFullScreen(this.props.selectedInstance);

    if (
      !isNullOrUndefined(this.props.section) &&
      !isNullOrUndefined(this.props.selectedInstance) &&
      !isNullOrUndefined(this.props.selectedInstance.module) &&
      isNavigatingAwayFromSection &&
      !isFullScreen &&
      !this.props.section.isCompleted &&
      prevState.isSectionPaused === false &&
      this.state.isSectionPaused === false &&
      this.props.selectedInstance.module.examMode === ExamMode.ExamTime
    ) {
      this.isNavigatingFromSection = true;
      this.previousPagePaused = previousPathName[1];
      this.previousPausedOnUrl = previousProps.history.pathname;
      this.onPauseSection(this.isNavigatingFromSection);
    }

    if (!this.isNavigatingFromSection) {
      setCurrentInstance(
        this.isFlexOrApolloPage() ? this.props.selectedInstance : null,
        this.props.putInstanceState,
        this.exitFlex,
      );
    }
    if (
      !isNullOrUndefined(previousPathName) &&
      !isNullOrUndefined(currentPathName) &&
      previousPathName.length > 0 &&
      currentPathName.length > 0 &&
      previousPathName[1] !== currentPathName[1] &&
      (`/${currentPathName[1]}` === QUESTION_PATH ||
        `/${currentPathName[1]}` === EXAM_QUESTION_PATH) &&
      (`/${previousPathName[1]}` !== PASSAGE_PATH ||
        `/${previousPathName[1]}` !== EXAM_PASSAGE_PATH) &&
      (`/${previousPathName[1]}` !== DIRECTION_PATH ||
        `/${previousPathName[1]}` !== EXAM_DIRECTION_PATH)
    ) {
      this.props.onSetPassageView(false);
    }

    if (
      this.isFlexExamPaused() &&
      !isNullOrUndefined(previousPathName) &&
      !isNullOrUndefined(currentPathName) &&
      previousPathName.length > 0 &&
      currentPathName.length > 0 &&
      previousPathName[1] !== currentPathName[1] &&
      (`/${previousPathName[1]}` === DIRECTION_PATH ||
        `/${previousPathName[1]}` === EXAM_DIRECTION_PATH) &&
      (`/${currentPathName[1]}` === PASSAGE_PATH ||
        `/${currentPathName[1]}` === EXAM_PASSAGE_PATH ||
        `/${currentPathName[1]}` === QUESTION_PATH ||
        `/${currentPathName[1]}` === EXAM_QUESTION_PATH)
    ) {
      this.props.onFlexExamPause(false);
    }

    // REMOTE PAUSE
    if (
      this.isExamRemotelyPaused() &&
      !isNullOrUndefined(currentPathName) &&
      currentPathName.length > 0 &&
      // Not currently on the pause page:
      !EXAM_PAUSE_PATH.includes(currentPathName[2]) &&
      // currently on Passage, Exam Passage, Question, or Exam Question page:
      (PASSAGE_PATH.includes(currentPathName[1]) ||
        EXAM_PASSAGE_PATH.includes(currentPathName[1]) ||
        QUESTION_PATH.includes(currentPathName[1]) ||
        EXAM_QUESTION_PATH.includes(currentPathName[1]))
    ) {
      this.onRemotePause(EXAM_PAUSE_PATH, this.props.history.pathname);
    }

    // REMOTE RESUME
    if (
      this.isExamRemotelyResumed() &&
      !isNullOrUndefined(currentPathName) &&
      currentPathName.length > 0 &&
      // currently on the pause page
      EXAM_PAUSE_PATH.toLowerCase().includes(currentPathName[2].toLowerCase())
    ) {
      this.onRemoteResume();
    }

    if (
      !isNullOrUndefined(previousPathName) &&
      !isNullOrUndefined(currentPathName) &&
      previousPathName.length > 0 &&
      currentPathName.length > 0 &&
      previousPathName[1] !== currentPathName[1] &&
      (`/${currentPathName[1]}` === QUESTION_PATH ||
        `/${currentPathName[1]}` === EXAM_QUESTION_PATH) &&
      (`/${previousPathName[1]}` === PASSAGE_PATH ||
        `/${previousPathName[1]}` === EXAM_PASSAGE_PATH)
    ) {
      const stimulusHeader = document.getElementById(
        'sectionpage_questionPromptPassage',
      );
      if (!isNullOrUndefined(stimulusHeader)) {
        stimulusHeader.focus();
      }
    }

    // When cookiebot is initializaed it attaches this variable to the window
    if (window.CookiebotDialog && window.location.pathname.includes('/exam')) {
      window.CookiebotDialog.hide();
    }
  }

  appInitRedirectIfNeeded(isAppReady) {
    const url = window?.location?.pathname
      ? window.location.pathname + window.location.search
      : '/';

    if (!isAppReady && url.toLowerCase().indexOf(STARTUP_PATH) < 0) {
      history.replace(`${STARTUP_PATH}?url=${encodeURIComponent(url)}`);

      // Navigating from old to new certifying statement path
      if (
        window?.location?.pathname === '/certifyingStatement' ||
        window.location.search === '?url=%2FcertifyingStatement'
      ) {
        history.push('/exam/certifyingStatement');
      }

      return true;
    }
    return false;
  }

  toggleDropdown() {
    this.setState(previousState => ({
      ...previousState,
      dropdownOpen: !previousState.dropdownOpen,
    }));
  }

  onMenuBlur = () => {
    this.menuTimeOutId = setTimeout(() => {
      this.setState({ dropdownOpen: false });
    }, 200);
  };

  onMenuFocus = () => {
    clearTimeout(this.menuTimeOutId);
  };

  exitFlex = () => {
    this.isExitingFlex = true;
    setTimeout(() => {
      history.push(
        `${CONNECTIVITY_ERROR_PATH}/${
          this.props.selectedInstance.testInstanceId
        }?type=${
          this.props.selectedInstance.module.options.isLsatFlex
            ? 'flex'
            : 'writing'
        }`,
      );
    }, 1);
  };

  getAnswerText() {
    return 'Show Answer';
  }

  isItExamMode() {
    return (
      !isNullOrUndefined(this.props.selectedInstance) &&
      !isNullOrUndefined(this.props.selectedInstance.module.examMode) &&
      this.props.selectedInstance.module.examMode === ExamMode.ExamTime
    );
  }

  isDrillSet() {
    return (
      this.props?.selectedInstance?.module?.moduleType === ModuleType.DrillSet
    );
  }

  isAdditionalPracticeSet() {
    return (
      this.props?.selectedInstance?.module?.moduleType ===
      ModuleType.DrillSetAdditional
    );
  }

  isFlexExamPaused() {
    return (
      !isNullOrUndefined(this.props.selectedInstance) &&
      !moduleHelper.isLSATFlexInstance(this.props.selectedInstance) &&
      !isNullOrUndefined(this.props.selectedInstance.module.isFlexPaused) &&
      this.props.selectedInstance.module.isFlexPaused
    );
  }

  isExamRemotelyPaused() {
    return (
      this.props.testInstanceRemoteStatus?.remoteExamStatus?.examStatus?.toLowerCase() ===
      'pause'
    );
  }

  isExamRemotelyResumed() {
    return (
      this.props.testInstanceRemoteStatus?.remoteExamStatus?.examStatus?.toLowerCase() ===
      'resume'
    );
  }

  showAnswer() {
    if (!this.state.neverShowAgain) {
      this.setState({ popUpOpen: true });
    } else {
      let showAnswerMessage = '';
      if (this.props.section.items && !this.props.hasToShowAnswer) {
        const itemIndex = this.props.section.currentItemIndex;
        const correctAnswerOption = this.props.section.items[itemIndex]
          .correctAnswer;
        showAnswerMessage = `${this.props.intl.formatMessage(
          { ...messages.showAnswerLabel },
          {
            correctAnswerOption,
          },
        )}`;
      }
      document.getElementById('onShowAnswer').textContent = showAnswerMessage;
      this.props.showAnswer(!this.props.hasToShowAnswer);
      // eslint-disable-next-line no-restricted-globals
      let target = event.target ? event.target : event.srcElement;
      if (target && target.nodeName.toLowerCase() === 'button') {
        target.style.display = 'none';
      }
    }
  }

  showAnswerButton() {
    let questionAnswered = false;
    let showAnswerButtonClicked = false;
    if (this.props.currentSectionItem !== null) {
      questionAnswered = this.props.currentSectionItem.isAnswered;
      showAnswerButtonClicked = this.props.currentSectionItem.showAnswerClicked;
    }
    return (
      questionAnswered &&
      this.props.breadCrumbSection.isShowAnswerVisible &&
      !showAnswerButtonClicked &&
      !this.isItExamMode()
    );
  }

  getURLBylocation(location) {
    if (location && location != null) {
      return `${location}`;
    }
    return ' ';
  }

  getBreadCrumbSection() {
    let breadCrumbLinks = [];
    let navItems = decodeURI(window.location.pathname).split('/');
    // Remove empty string
    navItems.shift();
    if (navItems && navItems.length > 0) {
      if (navItems[0].toLocaleLowerCase().startsWith('tutorial')) {
        breadCrumbLinks = [...DEFAULT_TUTORIAL_BREADCRUMB_ITEMS];
        const title = getQueryVariable('title');
        if (!isNullOrUndefined(title)) {
          breadCrumbLinks.push({
            pathName: title,
            location: null,
          });
        }
      } else if (
        navItems.length >= 2 &&
        ((this.props.instances && this.props.instances.length > 0) ||
          this.props.historyModule)
      ) {
        const isDrillSetRedirect =
          this.isDrillSet() &&
          config.REACT_APP_IS_LIBRARY_NEXT_REDIRECT_ENABLED === 'true';

        const isAdditionalPracticeSetRedirect =
          this.isAdditionalPracticeSet() &&
          config.REACT_APP_IS_LIBRARY_NEXT_REDIRECT_ENABLED === 'true';

        if (isDrillSetRedirect) {
          breadCrumbLinks = [...DEFAULT_DRILLSET_BREADCRUMB_ITEMS];
        } else if (isAdditionalPracticeSetRedirect) {
          breadCrumbLinks = [
            ...DEFAULT_ADDITIONAL_PRACTICE_SET_BREADCRUMB_ITEMS,
          ];
        } else {
          breadCrumbLinks = [...DEFAULT_BREADCRUMB_ITEMS];
        }

        const moduleObj = this.props.instances.find(
          val => val.testInstanceId === navItems[1],
        );

        let moduleName = isNullOrUndefined(moduleObj)
          ? null
          : moduleObj.module.moduleName;
        if (navItems[0].toLocaleLowerCase().startsWith('history')) {
          moduleName = `${
            isNullOrUndefined(this.props.historyModule.moduleName)
              ? ''
              : this.props.historyModule.moduleName
          } History`;
        }
        const sectionBasedURLs = [
          DIRECTION_PATH,
          EXAM_DIRECTION_PATH,
          QUESTION_PATH,
          EXAM_QUESTION_PATH,
          PASSAGE_PATH,
          EXAM_PASSAGE_PATH,
        ];
        const hasContainSectionPath =
          sectionBasedURLs.some(
            p =>
              p.toLocaleLowerCase() === `/${navItems[0]}`.toLocaleLowerCase(),
          ) === true;

        // eslint-disable-next-line no-plusplus
        for (let index = 1; index < navItems.length; index++) {
          let pathName = index === 1 ? moduleName : navItems[index];
          const lastIndex =
            this.isDrillSet() || this.isAdditionalPracticeSet()
              ? index === 1
              : index > 1;
          if (
            !isNullOrUndefined(this.props.section) &&
            this.props.section.isCompleted === true &&
            lastIndex &&
            hasContainSectionPath
          ) {
            pathName = `${pathName} (Read Only)`;
          }
          let redirectPath =
            index === 1 && (this.isDrillSet() || this.isAdditionalPracticeSet())
              ? `${DIRECTION_PATH}/${navItems[index]}/Section ${
                  this.props.selectedInstance?.module?.sections[0].sectionOrder
                }`
              : `${MODULE_PATH}/${navItems[index]}`;
          if (
            !(
              (this.isDrillSet() || this.isAdditionalPracticeSet()) &&
              index > 1
            )
          ) {
            breadCrumbLinks.push({
              pathName,
              location: index > 1 ? `${navItems[2]}` : redirectPath,
            });
          }
        }
      }
    }

    const items = breadCrumbLinks;
    return items.map((item, index) => (
      <li
        key={`masterPage.li${item.pathName}`}
        className="master-page-list-without-style"
      >
        <React.Fragment key={`masterPageFragment.${item.pathName}`}>
          {index === items.length - 1 ? (
            <span
              id={`masterPage.${item.pathName}`}
              key={`masterPage.${item.pathName}`}
              data-testid={`masterPage.${item.pathName}`}
              aria-current="page"
            >
              {item.pathName}
            </span>
          ) : (
            <ReloadingLink
              // key is not a prop I can pass down, so I'm using the id field to add a key
              id={`masterPage.${item.pathName}`}
              key={`masterPage.${item.pathName}`}
              dataTestId={`masterPage.${item.pathName}`}
              history={history}
              to={`${this.getURLBylocation(item.location)}`}
              className={
                item.location && item.location != null ? '' : 'disableLink'
              }
              callFunctionBeforeReload={this.onBreadcrumbClicked}
            >
              <div>{item.pathName}</div>
            </ReloadingLink>
          )}
          {index !== items.length - 1 && (
            <span
              key={`masterPage.span${item.pathName}`}
              className="navSeparator"
              aria-hidden="true"
            >
              &nbsp; / &nbsp;
            </span>
          )}
        </React.Fragment>
      </li>
    ));
  }

  showSidebar = () => {
    this.props.onDisplaySideNavBar(true);
  };

  hideSidebar = () => {
    this.props.onDisplaySideNavBar(false);
  };

  isFocusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
    if (relatedTarget === null) return false;
    let node = relatedTarget.parentNode;
    while (node !== null) {
      if (node === currentTarget) return true;
      node = node.parentNode;
    }
    return false;
  };

  sidebarLinkClicked = () => {
    persistNow();
    this.hideSidebar();
    const appDiv = document.getElementById('app');
    if (!isNullOrUndefined(appDiv)) {
      appDiv.focus();
    }
  };

  closeMenuButtonClicked = () => {
    persistNow();
    this.hideSidebar();
    const hamMenu = document.getElementById('Homehamburger');
    const hamMenuCert = document.getElementById('Certhamburger');
    const hamMenuPrep = document.getElementById('hamburger');
    if (!isNullOrUndefined(hamMenu)) {
      setTimeout(() => {
        hamMenu.focus();
      }, 350);
    }
    if (!isNullOrUndefined(hamMenuCert)) {
      setTimeout(() => {
        hamMenuCert.focus();
      }, 350);
    }
    if (!isNullOrUndefined(hamMenuPrep)) {
      setTimeout(() => {
        hamMenuPrep.focus();
      }, 350);
    }
  };

  onBreadcrumbClicked = () => {
    persistNow();
    this.sidebarLinkClicked();
  };

  getTopButtonContainerRightPosition = () => {
    const position = Math.round(document.body.clientWidth * 0.002);
    return position < 5 ? 5 : position;
  };

  getTopBarPadding = () => {
    const position = Math.round(document.body.clientWidth * 0.0099);
    return position < 6 ? 6 : position;
  };

  updateTopNav() {
    const isSectionCompleted = !isNullOrUndefined(this.props.section)
      ? this.props.section.isCompleted
      : false;
    const completeMessage =
      this.isDrillSet() || this.isAdditionalPracticeSet()
        ? `${this.props.intl.formatMessage({
            ...messages.submitButton,
          })}`
        : `${this.props.intl.formatMessage({
            ...messages.completeSectionButton,
          })}`;
    const completeSectionText = isSectionCompleted
      ? `${this.props.intl.formatMessage({
          ...messages.backButton,
        })}`
      : completeMessage;
    const nextSectionText = `${this.props.intl.formatMessage({
      ...messages.nextSectionButton,
    })}`;
    const menuButtonClass = this.state.dropdownOpen ? 'MenuButtonActive' : '';
    const hasToShowNextSection =
      this.props.breadCrumbSection.isCompleteSectionVisible === true &&
      this.isCurrentAndNextSectionsCompleted() === true;
    const hasToShowCompleteSection =
      this.props.breadCrumbSection.isCompleteSectionVisible === true &&
      (getSectionOptions(this.props.section).showCompleteSectionButton ||
        isSectionCompleted);
    const hasToShowPauseSection =
      this.props.breadCrumbSection.isCompleteSectionVisible === true &&
      getSectionOptions(this.props.section).showPauseSectionButton &&
      !isSectionCompleted;

    return (
      <React.Fragment>
        <nav
          className="breadcrumbs"
          aria-label={`${this.props.intl.formatMessage({
            ...messages.breadcrumbNav,
          })}`}
        >
          <div className="breadCrumbDiv">
            <ul className="master-page-list-without-style">
              {this.getBreadCrumbSection()}
            </ul>
          </div>
          <div id="masterTopBarButtonContainer">
            <span
              className="hidden"
              id="onShowAnswer"
              aria-live="assertive"
              aria-atomic="true"
            />
            {this.showAnswerButton() && isSectionCompleted === false && (
              <>
                {document.body.clientWidth < 850 &&
                  document.body.clientWidth >
                    parseInt(config.REACT_APP_MOBILE_WIDTH_BREAKPOINT, 10) && (
                    <style
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: `
                          #masterTopBarButtonContainer
                          {
                            right: ${this.getTopButtonContainerRightPosition()}px;
                          }
                          #masterTopBarButtonContainer button {
                            margin-left: 1px;
                            margin-right: 1px;
                        }
                        .ToolbarButton{
                          padding:0 ${this.getTopBarPadding()}px;
                        }
                        @media (max-width: 753px){
                          .hamburger{
                          margin-right: 0px;
                          }
                        }
                     `,
                      }}
                    />
                  )}
                <ToolbarButton
                  onToolBarButtonClick={this.showAnswer}
                  aria-controls="onShowAnswer"
                  className={
                    this.isDrillSet() || this.isAdditionalPracticeSet()
                      ? 'drill-set secondary'
                      : ''
                  }
                >
                  {this.getAnswerText()}
                </ToolbarButton>
                &nbsp;
              </>
              // eslint-disable-next-line indent
            )}
            {hasToShowPauseSection && (
              <ToolbarButton
                onToolBarButtonClick={() => this.onPauseSection(true)}
                className={
                  this.isDrillSet() || this.isAdditionalPracticeSet()
                    ? 'drill-set secondary'
                    : ''
                }
              >
                {this.getPauseText()}
              </ToolbarButton>
            )}
            {hasToShowCompleteSection && (
              <>
                &nbsp;
                <ToolbarButton
                  onToolBarButtonClick={this.onCompleteSection}
                  className={
                    this.isDrillSet() || this.isAdditionalPracticeSet()
                      ? 'drill-set primary'
                      : ''
                  }
                >
                  {completeSectionText}
                </ToolbarButton>
              </>
            )}
            {hasToShowNextSection &&
              !this.isDrillSet() &&
              !this.isAdditionalPracticeSet() && (
                <ToolbarButton
                  id="nextSectionButton"
                  onToolBarButtonClick={this.onNextSection}
                >
                  {nextSectionText}
                </ToolbarButton>
              )}
          </div>
          {(this.props.breadCrumbSection.isCompleteSectionVisible ||
            this.props.breadCrumbSection.isCompleteSectionVisible) && (
            <div id="masterTopBarMoreButtonContainer">
              <DropdownMenu
                title="More&nbsp;"
                accessibleName="more-dropdown"
                toggle={this.toggleDropdown}
                isOpen={this.state.dropdownOpen}
                onBlurHandler={this.onMenuBlur}
                onFocusHandler={this.onMenuFocus}
                className={menuButtonClass}
                label="More"
              >
                {this.showAnswerButton() && isSectionCompleted === false && (
                  <DropdownItem
                    onClick={this.showAnswer}
                    className="moreMenuItem"
                  >
                    {this.getAnswerText()}
                  </DropdownItem>
                )}
                {this.props.breadCrumbSection.isCompleteSectionVisible &&
                  !isSectionCompleted && (
                    <DropdownItem
                      onClick={this.onPauseSection}
                      className="moreMenuItem"
                    >
                      {this.getPauseText()}
                    </DropdownItem>
                  )}
                {hasToShowCompleteSection && (
                  <DropdownItem
                    onClick={this.onCompleteSection}
                    className="moreMenuItem"
                  >
                    {completeSectionText}
                  </DropdownItem>
                )}
                {hasToShowNextSection === true && (
                  <DropdownItem
                    onClick={this.onNextSection}
                    className="moreMenuItem"
                  >
                    {nextSectionText}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </div>
          )}
        </nav>
      </React.Fragment>
    );
  }

  navigateTo = href => {
    this.hideSidebar();
    history.push(href);
  };

  shouldShowPageHeader = () => {
    const { pathname } = window?.location;
    const isExamRoute = pathname?.indexOf('/exam') >= 0;
    const isRestrictedPath = PATHS_NOT_ALLOWED_FOR_HEADER.some(
      path => pathname?.indexOf(path) >= 0,
    );

    return !(isRestrictedPath > 0 && isExamRoute) && this.props.isAppReady;
  };

  /**
   *
   * @param {SyntheticEvent} e
   * @description When utilizing the side nav on tablet and mobile screen widths,
   * PageHeader interactable elements will have a negative tabIndex (except UserProfile).
   * When a user tabs off of the last item in the side nav / shift+tabs off the first item,
   * the focus will go to the next logical element from a UX perspective. When the side nav
   * closes, the tabIndex of other PageHeader elements will return to zero.
   */
  onSideNavKeyDown = e => {
    // Booleans
    const isTabKey = e.which === 9;
    const isShiftPlusTab = e.shiftKey && isTabKey;
    const helpLink = document.getElementById('helptext');
    const isHelpLink = e.target.href === 'https://lsac.zendesk.com/hc/en-us';
    const isCloseNavButton = e.target.id === this.sidebarCloseButtonId;
    const isCloseNavButtonRebrand =
      e.target.id === this.sidebarCloseButtonIdRebrand;
    const isSignOutButtonNav = e.target.id === 'sign-out-button-nav';
    const isTabletWidth = window.innerWidth > 675 && window.innerWidth <= 1170;
    const isHeaderWithBreadcrumbs = this.props.breadCrumbSection.isVisible;
    // Focusable elements
    const hamburgerButton = document.querySelector('.hamburgerButton');
    const isLawHubLogoInNav = e.target === document.getElementById('lsacLink');
    const mainNavLinks = document
      .getElementById('masterPageSidebar')
      .querySelectorAll('ul a');
    const isLastLinkInMainNav =
      mainNavLinks[mainNavLinks.length - 1] === e.target;

    const closeNavAndFocusOnHamButton = () => {
      this.closeMenuButtonClicked();
      document.getElementById('hamburger')?.focus();
    };

    // Forward tabbing
    if (isTabKey && !isShiftPlusTab) {
      // Tablet screen width and a page with new header / doesn't have breadcrumbs
      if (
        !helpLink &&
        isLastLinkInMainNav &&
        !isHeaderWithBreadcrumbs &&
        this.shouldShowPageHeader()
      ) {
        if (
          !this.props.userDetails ||
          config.REACT_APP_ENABLE_NEW_NAV !== 'true'
        ) {
          closeNavAndFocusOnHamButton();
        }
      } else if (
        this.props.userDetails &&
        isTabletWidth &&
        isSignOutButtonNav &&
        !isHeaderWithBreadcrumbs &&
        this.shouldShowPageHeader()
      ) {
        closeNavAndFocusOnHamButton();
      }
      // Page with breadcrumb header and help link visible in side nav
      else if (
        isHelpLink &&
        isHeaderWithBreadcrumbs &&
        !this.shouldShowPageHeader()
      ) {
        // Place focus on element preceding the breadcrumbs in forward tabbing order
        this.hideSidebar();
        hamburgerButton.focus();
      }
      // When help link is visible in the side nav
      else if (isHelpLink) {
        closeNavAndFocusOnHamButton();
      }
    }

    // Backward tabbing: focus is placed on the hamburger button
    if (isShiftPlusTab && (isCloseNavButton || isCloseNavButtonRebrand)) {
      if (
        config.REACT_APP_ENABLE_NEW_NAV !== 'true' ||
        (config.REACT_APP_ENABLE_NEW_NAV === 'true' && isTabletWidth)
      ) {
        closeNavAndFocusOnHamButton();
      }
    }
    if (
      isShiftPlusTab &&
      isLawHubLogoInNav &&
      config.REACT_APP_ENABLE_NEW_NAV === 'true'
    ) {
      closeNavAndFocusOnHamButton();
    }
  };

  /**
   *
   * @param {SyntheticEvent} e
   * @description Limit closing of side nav to 'Enter' and 'Space' keys.
   * Also assign focus back to hamburger button after nav is not visible
   */
  onSideNavCloseButtonKeypress = async e => {
    const hamburgerButton = document.querySelector('.hamburgerButton');
    e.preventDefault();
    // Using both .which and .key for maximum browser compatability
    const enterKeyPressed = e.which === 13 || e.key === 'Enter';
    const spaceKeyPressed = e.which === 32 || e.key === ' ';
    if (enterKeyPressed || spaceKeyPressed || e.type === 'click') {
      this.closeMenuButtonClicked();
    }
  };

  onSidebarTransitionEnd() {
    const nav = document.getElementById(this.sidebarId);
    if (!isNullOrUndefined(nav)) {
      nav.setAttribute('aria-expanded', `${this.props.isSideNavBarVisible}`);
      nav.setAttribute('aria-hidden', `${!this.props.isSideNavBarVisible}`);
    }
    const closeButton = document.getElementById(this.sidebarCloseButtonId);
    if (!isNullOrUndefined(closeButton) && this.props.isSideNavBarVisible) {
      closeButton.focus();
    }
  }

  isSectionPage() {
    const pathName = !isNullOrUndefined(this.props.history)
      ? this.props.history.pathname
      : null;
    if (!isNullOrUndefined(pathName)) {
      const sectionPaths = [
        DIRECTION_PATH,
        PASSAGE_PATH,
        QUESTION_PATH,
        EXAM_DIRECTION_PATH,
        EXAM_PASSAGE_PATH,
        EXAM_QUESTION_PATH,
      ];
      const isSectionPath = sectionPaths.some(path =>
        pathName.toLowerCase().startsWith(path.toLowerCase()),
      );
      return isSectionPath;
    }
    return false;
  }

  isModulePage() {
    const pathName = !isNullOrUndefined(this.props.history)
      ? this.props.history.pathname
      : null;
    if (!isNullOrUndefined(pathName)) {
      const modulePaths = [MODULE_PATH, EXAM_MODULE_PATH];
      const isModulePath = modulePaths.some(path =>
        pathName.toLowerCase().startsWith(path.toLowerCase()),
      );
      return isModulePath;
    }
    return false;
  }

  isFlexOrApolloPage() {
    if (this.isApolloPage()) {
      return true;
    }
    const pathName = !isNullOrUndefined(this.props.history)
      ? this.props.history.pathname
      : null;
    if (!isNullOrUndefined(pathName)) {
      const flexPaths = [
        TEST_COMPLETE_FLEX_PATH,
        CONNECTIVITY_ERROR_PATH,
        INTERMISSION_PATH,
      ];
      const isFlexPath = flexPaths.some(path =>
        pathName.toLowerCase().startsWith(path.toLowerCase()),
      );
      return isFlexPath;
    }
    return false;
  }

  isApolloPage() {
    const pathName = !isNullOrUndefined(this.props.history)
      ? this.props.history.pathname
      : null;
    if (!isNullOrUndefined(pathName)) {
      const apolloPaths = [
        DIRECTION_PATH,
        EXAM_DIRECTION_PATH,
        PASSAGE_PATH,
        EXAM_PASSAGE_PATH,
        QUESTION_PATH,
        EXAM_QUESTION_PATH,
        TEST_COMPLETE_PATH,
        EXAM_TEST_COMPLETE_PATH,
        TEST_REVIEW_PATH,
        EXAM_TEST_REVIEW_PATH,
        MODULE_PATH,
        EXAM_MODULE_PATH,
      ];
      const isApolloPath = apolloPaths.some(path =>
        pathName.toLowerCase().startsWith(path.toLowerCase()),
      );
      return isApolloPath;
    }
    return false;
  }

  showApolloFullScreen() {
    if (
      this.showFullScreenConnectError() ||
      this.showFullScreenPlusProgram() ||
      this.showFullScreenWebinarJoinPage()
    ) {
      return true;
    }
    if (this.props.history.pathname.startsWith(INTERMISSION_PATH)) {
      return true;
    }
    return (
      this.isApolloPage() &&
      !isNullOrUndefined(this.props.selectedInstance) &&
      moduleHelper.showFullScreen(this.props.selectedInstance)
    );
  }

  showFullScreenPlusProgram() {
    return (
      this.props.history &&
      this.props.history.pathname &&
      (this.props.history.pathname.startsWith(PLUS_PROGRAM_REGISTRATION) ||
        this.props.history.pathname.startsWith(
          PLUS_PROGRAM_REGISTRATION_COMPLETE,
        ))
    );
  }

  // Shows webinar jon page in fullscreen
  showFullScreenWebinarJoinPage() {
    return (
      this.props.history &&
      this.props.history.pathname &&
      this.props.history.pathname.startsWith(EVENT_JOIN_PATH)
    );
  }

  showFullScreenConnectError() {
    if (isNullOrUndefined(this.props.history.pathname)) {
      return false;
    }
    if (
      this.props.history.pathname.startsWith(CONNECTIVITY_ERROR_PATH) &&
      this.props?.selectedInstance?.module?.options?.showReconnectFullScreen ===
        true
    ) {
      return true;
    }
    return false;
  }

  addSkipToMainLink() {
    const skipId = document.getElementById('skipToMainLink');
    const currentPath = this.props.history.pathname;
    let isCurrentPathFromWriting = [
      MODULE_PATH,
      EXAM_MODULE_PATH,
      INTERMISSION_PATH,
      DIRECTION_PATH,
      EXAM_DIRECTION_PATH,
      QUESTION_PATH,
    ].some(word => currentPath.startsWith(word));

    if (!skipId && !isCurrentPathFromWriting) {
      const element = document.createElement('a');
      element.setAttribute('id', 'skipToMainLink');
      element.setAttribute('aria-live', 'off');
      element.setAttribute('href', '#masterContentPane');
      element.text = 'Skip to main content';
      element.className = 'skipToMainLink';

      const rootDiv = document.getElementById('app');
      if (rootDiv) {
        rootDiv.insertBefore(element, rootDiv.children[0]);
      }
    }
  }

  onTechnicalPause(path) {
    addTechnicalPauseInitiated();
    history.push(path);
  }

  onRemotePause(newPath, currentPath) {
    // save the path we were just on :
    this.previousPathWhenPaused = currentPath;
    history.push(newPath);
  }

  onRemoteResume() {
    history.push(this.previousPathWhenPaused);
  }

  isTechnicalPauseAllowed() {
    if (this.props.exams?.length > 0) {
      const exam = getCurrentExam(this.props.exams);
      if (exam) {
        // The allowTechnicalPause key can be true/false/null and Technical Pause will be hidden when allowTechnicalPause is false
        return exam.allowTechnicalPause !== false;
      }
      return false;
    }
    return false;
  }

  render() {
    const w =
      this.state.size.width === 0
        ? `${window.innerWidth}px`
        : `${this.state.size.width}px`;
    const h =
      this.state.size.height === 0
        ? `${window.innerHeight}px`
        : `${this.state.size.height + 1}px`;
    const pathName = isNullOrUndefined(history)
      ? window.location.pathname
      : history.location.pathname;
    this.setDocumentTitleByPath(pathName);
    const isFullScreen = this.props.isAppReady && this.showApolloFullScreen();
    const isTabletOrMobileView = document.body.clientWidth <= 1170;
    const hasToShowSideNavBar =
      this.props.isSideNavBarVisible || !isTabletOrMobileView;

    const isBreadcrumbEnabled =
      this.props.IsBreadcrumbsEnabled &&
      this.props.breadCrumbSection &&
      this.props.breadCrumbSection.isVisible === true;

    let getMasterPageClassName = 'flexbox-container ';
    getMasterPageClassName +=
      this.isDrillSet() || this.isAdditionalPracticeSet() ? ' drillSet' : '';

    return (
      <React.Fragment>
        <Helmet>
          <meta
            name="description"
            content={messages.metaDescription.defaultMessage}
          />
        </Helmet>
        <div
          id="parent-flexbox-container"
          className={
            this.isDrillSet() || this.isAdditionalPracticeSet()
              ? 'flexbox-container drillSet'
              : 'flexbox-container new-branding'
          }
          style={{ width: w, height: h, maxWidth: w, maxHeight: h }}
        >
          {!isFullScreen && (
            <SidebarNav
              sidebarId={this.sidebarId}
              sidebarCloseButtonId={this.sidebarCloseButtonId}
              sidebarCloseButtonIdRebrand={this.sidebarCloseButtonIdRebrand}
              pathname={this.props.history.pathname}
              isFullScreen={isFullScreen}
              isSideNavBarVisible={this.props.isSideNavBarVisible}
              isHeaderWithHelpLink={this.shouldShowPageHeader()}
              isAppReady={this.props.isAppReady}
              hasToShowSideNavBar={hasToShowSideNavBar}
              onDisplaySideNavBar={this.props.onDisplaySideNavBar}
              onSideNavCloseButtonKeypress={this.onSideNavCloseButtonKeypress}
              onSideNavKeyDown={this.onSideNavKeyDown}
              sidebarLinkClicked={this.sidebarLinkClicked}
              hideSidebar={this.hideSidebar}
              closeMenuButtonClicked={this.closeMenuButtonClicked}
              collapseSidebar={this.collapseSidebar}
              history={history}
              userDetails={this.props.userDetails}
              windowWidth={window.innerWidth}
              configShowFlexList={config.REACT_APP_SHOW_FLEX_LIST}
              intl={this.props.intl}
              exams={this.props.exams}
            />
          )}
          {this.shouldShowPageHeader() && (
            <PageHeader userDetails={this.props.userDetails} />
          )}
          {!isFullScreen && isBreadcrumbEnabled && (
            <div className="topNavigation">{this.updateTopNav()}</div>
          )}
          <section
            role="main"
            className="masterContentPane"
            id="masterContentPane"
            tabIndex="-1"
            aria-labelledby="regionHeading"
            aria-describedby="regionHeading"
          >
            {(this.props.isAppReady ||
              window.location.href.indexOf(STARTUP_PATH) >= 0) &&
              this.props.children}
          </section>
          {this.state.isSectionPaused && (
            <AccessibleModal
              isOpen={this.state.isSectionPaused}
              onExit={this.onClosePauseSectionPopup}
              onClosed={this.onClosePauseSectionPopup}
              modalClassName="showPauseModal"
              describedBy="spn_pauseSection"
              returnFocusAfterClose
            >
              <ModalBody className="masterModalBody">
                <h2 className="notice-heading">{this.notice} </h2>

                <span id="spn_pauseSection">{this.pausePopupMessage}</span>
                <br />
                <div className="cancelContButtonDiv">
                  <Button
                    id="cancelButton"
                    color="secondary"
                    onClick={this.onClosePauseSectionPopup}
                  >
                    {`${this.props.intl.formatMessage({
                      ...messages.showAnswerCancelButton,
                    })}`}
                  </Button>
                  <span aria-hidden="true">&nbsp;&nbsp;</span>
                  <Button color="primary" onClick={this.onPauseSection}>
                    {`${this.props.intl.formatMessage({
                      ...messages.pauseSectionPopupOk,
                    })}`}
                  </Button>
                </div>
                <div>
                  <input type="checkbox" id="chkNeverShowPausePopup" />
                  {'  '}
                  <span>{`${this.props.intl.formatMessage({
                    ...messages.neverShowPausePopUp,
                  })}`}</span>
                </div>
              </ModalBody>
            </AccessibleModal>
          )}
          {this.state.popUpOpen ? (
            <AccessibleModal
              isOpen={this.state.popUpOpen}
              toggle={this.togglePopUp}
              onExit={this.onCloseShowAnswerPopup}
              onClosed={this.onCloseShowAnswerPopup}
              modalClassName="showAnswerModal"
              describedBy="spn_showAnswer"
              returnFocusAfterClose
            >
              <ModalBody className="masterModalBody">
                <span id="spn_showAnswer">
                  {`${this.props.intl.formatMessage({
                    ...messages.showAnswerPopUpMessage,
                  })}`}
                </span>
                <div className="cancelContButtonDiv">
                  <Button
                    id="cancelButton"
                    color="secondary"
                    onClick={this.onCloseShowAnswerPopup}
                  >
                    {`${this.props.intl.formatMessage({
                      ...messages.showAnswerCancelButton,
                    })}`}
                  </Button>
                  <span aria-hidden="true"> &nbsp; &nbsp;</span>
                  <Button
                    color="primary"
                    id="continueButton"
                    onClick={this.onContinueShowAnswerPopUp}
                  >
                    {`${this.props.intl.formatMessage({
                      ...messages.showAnswerContinueButton,
                    })}`}
                  </Button>
                </div>
                <label>
                  <input
                    id={this.neverShowAnsModalCheckBoxId}
                    type="checkbox"
                    onChange={this.onCheckNeverShowAgain}
                  />
                  {' '}
                  <span>
                    {`${this.props.intl.formatMessage({
                      ...messages.neverShowAnswerPopUp,
                    })}`}
                  </span>
                </label>
              </ModalBody>
            </AccessibleModal>
          ) : null}
          <div id="footerSection" role="contentinfo" className="footerSection">
            <span />
            {moduleHelper.isLSATFlexInstance(this.props.selectedInstance) &&
              this.isApolloPage() &&
              this.isTechnicalPauseAllowed() && (
                <button
                  className="button-looks-like-link"
                  onClick={() =>
                    this.onTechnicalPause(
                      this.props.selectedInstance.module.options.startPath,
                    )
                  }
                >
                  {`${this.props.intl.formatMessage({
                    ...messages.technicalIssueLinkText,
                  })}`}
                </button>
              )}
            <span className="footer-right">
              All content &copy; {new Date().getFullYear()} Law School Admission
              Council, Inc. All rights reserved.
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MasterPage.propTypes = {
  match: PropTypes.object,
  ActiveNavItem: PropTypes.string,
  IsBreadcrumbsEnabled: PropTypes.string,
  history: PropTypes.object,
  // SectionName: PropTypes.string,
  SectionHref: PropTypes.string,
  showAnswer: PropTypes.func,
  putInstanceState: PropTypes.func,
  hasToShowAnswer: PropTypes.bool,
  section: PropTypes.object,
  children: PropTypes.object,
  breadCrumbSection: PropTypes.object,
  selectedInstance: PropTypes.object,
  historyModule: PropTypes.object,
  instances: PropTypes.array,
  onSectionComplete: PropTypes.func,
  onSectionUnLoad: PropTypes.func,
  intl: intlShape,
  currentSectionItem: PropTypes.object,
  onSectionPause: PropTypes.func,
  pauseButton: PropTypes.bool,
  isSideNavBarVisible: PropTypes.bool,
  onSetPassageView: PropTypes.func,
  onDisplaySideNavBar: PropTypes.func,
  userDetails: PropTypes.object,
  onFlexExamPause: PropTypes.func,
  onChangeHighlight: PropTypes.func,
  onSetIsWritingSection: PropTypes.func,
  ongetFlexExamListError: PropTypes.func,
  isAppReady: PropTypes.bool,
  onUpdateWindowSize: PropTypes.func,
  modules: PropTypes.array,
  exams: PropTypes.array,
  testInstanceRemoteStatus: PropTypes.object,
};

MasterPage.defaultProps = {
  ActiveNavItem: 'Practice',
  IsBreadcrumbsEnabled: GET_ISBREADCRUMBSEANBLED,
  // SectionName: 'Section',
  SectionHref: '#',
  selectedInstance: {},
};

const mapStateToProps = createStructuredSelector({
  section: sectionSelector(),
  history: routerSelector(),
  breadCrumbSection: breadCrumbSectionSelector(),
  selectedInstance: selectedInstanceSelector(),
  instances: instancesSelector(),
  historyModule: historyModuleSelector(),
  hasToShowAnswer: showAnswerSelector(),
  currentSectionItem: currentItemSelector(),
  pauseButton: pauseSectionSelector(),
  isSideNavBarVisible: showSideNavSelector(),
  userDetails: userDetailsSelector(),
  isAppReady: isAppReadySelector(),
  modules: itemsSelector(),
  exams: flexExamsSelector(),
  testInstanceRemoteStatus: testInstanceRemoteStatusSelector(),
});

/**
 *
 * Redux map dispatch to props method
 *
 */
export function mapDispatchToProps(dispatch) {
  return {
    showAnswer: showAnswerToggle => dispatch(showAnswer(showAnswerToggle)),
    onSectionComplete: () => dispatch(setSectionComplete()),
    onSectionUnLoad: () => dispatch(sectionUnLoadAction()),
    onSectionPause: (isSectionPaused, pagePaused, isNeverShowPauseMessage) =>
      dispatch(
        onPauseSection(isSectionPaused, pagePaused, isNeverShowPauseMessage),
      ),
    putInstanceState: data => dispatch(putInstanceStateAction(data)),
    onSetPassageView: isPassageView =>
      dispatch(setPassageViewAction(isPassageView)),
    onDisplaySideNavBar: hasToShowSideNavBar =>
      dispatch(displaySideNavBar(hasToShowSideNavBar)),
    onFlexExamPause: (isPaused, pausedOnPage) =>
      dispatch(pauseFlexExamAction(isPaused, pausedOnPage)),
    onChangeHighlight: highlightType =>
      dispatch(changeHighlightAction(highlightType)),
    onSetIsWritingSection: isWritingMode =>
      dispatch(setIsWritingSection(isWritingMode)),
    ongetFlexExamListError: err => dispatch(getFlexExamListErrorAction(err)),
    onUpdateWindowSize: size => dispatch(updateWindowSize(size)),
  };
}

const withDirectionsReducer = injectReducer({
  key: 'directionsPage',
  reducer: directionsPageReducer,
});

const withSessionReducer = injectReducer({
  key: SESSION_REDUX_NAME,
  reducer: appInitPageReducer,
});

const withTestSelectReducer = injectReducer({
  key: 'testSelectPage',
  reducer: testSelectPageReducer,
});

const withTestSelectSaga = injectSaga({
  key: 'testSelectPage',
  saga: testSelectPageSaga,
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withDirectionsReducer,
  withSessionReducer,
  withTestSelectReducer,
  withTestSelectSaga,
)(injectIntl(MasterPage));
