/* eslint-disable indent */
/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import { config } from 'config';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import MarketplacePage from 'containers/MarketplacePage/Loadable';
import RecommendedReadingPage from 'containers/MarketplacePage/LoadableRecommendedReading';
import SiteMap from 'containers/SiteMap/Loadable';
import TestSelectPage from 'containers/TestSelectPage/Loadable';
import TutorialsPage from 'containers/TutorialsPage/Loadable';
import UpgradePage from 'containers/UpgradePage/Loadable';
import VideoPlayerPage from 'containers/VideoPlayerPage/Loadable';
import BrowserErrorPage from '../BrowserErrorPage/index';
import ErrorPage from '../ErrorPage/index';
import PMCompletePage from '../PMCompletePage/index';
import SignupPage from '../SignupPage/index';
import WritingExamCompletePage from '../WritingExamCompletePage';
import ProtectedRoute from '../../components/ProtectedRoute';
import ApiErrorPage from '../ApiErrorPage';
import AppInitPage from '../AppInitPage';
import ApplicationStatusOnlinePage from '../ApplicationStatusOnlinePage';
import ArticlePage from '../ArticlePage';
import FlexConnectivityErrorPage from '../FlexConnectivityErrorPage';
import PlusProgramWelcome from '../PlusProgramWelcome';
import PlusRegCompletePage from '../PlusRegCompletePage';
import ToolsPage from '../ToolsPage';
import {
  ACCOUNT_SETTINGS_PATH,
  ADDITIONAL_PRACTICE_TEST_PATH,
  API_ERROR_PATH,
  APPLICATION_ONLINE_STATUS_PAGE,
  ARTICLE_PATH,
  BROWSER_ERROR_PATH,
  CERTIFYING_STATEMENT_PATH,
  CONFIRM_EMAIL,
  CONNECTIVITY_ERROR_PATH,
  DEEPLINK_PATH,
  DIRECTION_PATH,
  DRILLSET_PATH,
  ERROR_SCREEN_PATH,
  EXAM_COMPLETE_PATH,
  EXAM_CONFIRM_COMPLETE_PATH,
  EXAM_DIRECTION_PATH,
  EXAM_MODULE_PATH,
  EXAM_PASSAGE_PATH,
  EXAM_PAUSE_PATH,
  EXAM_QUESTION_PATH,
  EXAM_START_PATH,
  EXAM_TEST_COMPLETE_PATH,
  EXAM_TEST_REVIEW_PATH,
  EXPLORE_PATH,
  FLEX_CONGRATS_PATH,
  FLEX_LANDING_PATH,
  HISTORY_PATH,
  HOME_PATH,
  INTERMISSION_PATH,
  LEARNING_COLLECTION_PATH,
  LEARNING_LIBRARY_PATH,
  LIBRARY_PATH,
  LSAT_FLEX_PATH,
  MARKETPLACE_PATH,
  MODULE_PATH,
  PASSAGE_PATH,
  PLUS_PROGRAM_REGISTRATION,
  PLUS_PROGRAM_REGISTRATION_COMPLETE,
  PODCAST_PATH,
  PROCTORING_INITIALIZE_PATH,
  PROMETRICS_COMPLETE_PATH,
  QUESTION_PATH,
  RECOMMENDED_READING_PATH,
  SCORE_REPORT_PATH,
  SIGNUP_PAGE_PATH,
  STARTUP_PATH,
  TEST_COMPLETE_FLEX_PATH,
  TEST_COMPLETE_PATH,
  TEST_DURATION_ENDED,
  TEST_REVIEW_PATH,
  TOOLS_PATH,
  TUTORIALS_PATH,
  TUTORIAL_PATH,
  UPGRADE_PATH,
  WRITING_ERROR_SCREEN_PATH,
  WRITING_EXAM_COMPLETE_FINAL_PATH,
  WRITING_EXAM_PAGE,
  LST_PATH,
  OFFLINE_ERROR_PATH,
} from './constants';
// eslint-disable-next-line import/no-named-as-default-member
import AppErrorUI from '../../components/ErrorBoundary/AppErrorUI';
import WritingErrorUI from '../../components/WritingErrorUI';
import NextRedirect from '../../components/NextRedirect';
import TestDriverRouter from './TestDriverRouter';

export default function App(props) {
  const { isAppReady } = useSelector(state => state.athenaSession);

  const POST_LOGIN_ROUTES = [
    LSAT_FLEX_PATH,
    FLEX_LANDING_PATH,
    FLEX_LANDING_PATH,
    CERTIFYING_STATEMENT_PATH,
    HISTORY_PATH,
    TEST_COMPLETE_PATH,
    EXAM_TEST_COMPLETE_PATH,
    SCORE_REPORT_PATH,
    QUESTION_PATH,
    EXAM_QUESTION_PATH,
    PASSAGE_PATH,
    EXAM_PASSAGE_PATH,
    EXAM_DIRECTION_PATH,
    DIRECTION_PATH,
    TEST_REVIEW_PATH,
    EXAM_TEST_REVIEW_PATH,
    EXAM_MODULE_PATH,
    MODULE_PATH,
    TEST_COMPLETE_FLEX_PATH,
    EXAM_CONFIRM_COMPLETE_PATH,
    FLEX_CONGRATS_PATH,
    INTERMISSION_PATH,
    EXAM_START_PATH,
    EXAM_COMPLETE_PATH,
    WRITING_EXAM_PAGE,
    EXAM_PAUSE_PATH,
    PROCTORING_INITIALIZE_PATH,
    CONFIRM_EMAIL,
    DEEPLINK_PATH,
    TEST_DURATION_ENDED,
    DRILLSET_PATH,
  ];

  return (
    <div>
      <Switch>
        <Route exact path={SIGNUP_PAGE_PATH} component={SignupPage} />
        <Route exact path={HOME_PATH}>
          {config.REACT_APP_ENABLE_ATHENA_NEXT_REDIRECT === 'true' &&
            isAppReady && <NextRedirect />}
        </Route>
        <Route path={LEARNING_LIBRARY_PATH}>
          {config.REACT_APP_ENABLE_ATHENA_NEXT_REDIRECT === 'true' &&
            isAppReady && <NextRedirect />}
        </Route>
        <Route exact path={LST_PATH}>
          {config.REACT_APP_ENABLE_ATHENA_NEXT_REDIRECT === 'true' &&
            isAppReady && <NextRedirect />}
        </Route>
        <Route exact path={[LIBRARY_PATH, DRILLSET_PATH]}>
          {config.REACT_APP_IS_LIBRARY_NEXT_REDIRECT_ENABLED === 'true'
            ? isAppReady && <NextRedirect />
            : isAppReady && <TestSelectPage />}
        </Route>
        <Route exact path={[LIBRARY_PATH, ADDITIONAL_PRACTICE_TEST_PATH]}>
          {config.REACT_APP_IS_LIBRARY_NEXT_REDIRECT_ENABLED === 'true'
            ? isAppReady && <NextRedirect />
            : isAppReady && <TestSelectPage />}
        </Route>
        <Route
          path={POST_LOGIN_ROUTES}
          render={({ match }) => (
            <ProtectedRoute>
              <TestDriverRouter {...props} match={match} />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path={`${CONNECTIVITY_ERROR_PATH}/:testInstanceId?`}
          render={({ match }) => (
            <FlexConnectivityErrorPage
              testInstanceId={match.params.testInstanceId}
            />
          )}
        />
        <Route path={TUTORIALS_PATH} component={TutorialsPage} />
        <Route path={MARKETPLACE_PATH}>
          {config.REACT_APP_ENABLE_ATHENA_NEXT_REDIRECT === 'true'
            ? isAppReady && <NextRedirect />
            : isAppReady && <MarketplacePage />}
        </Route>
        <Route
          path={RECOMMENDED_READING_PATH}
          component={RecommendedReadingPage}
        />
        <Route path={TUTORIAL_PATH} component={VideoPlayerPage} />
        <Route path={EXPLORE_PATH}>
          {config.REACT_APP_ENABLE_ATHENA_NEXT_REDIRECT === 'true' &&
            isAppReady && <NextRedirect />}
        </Route>
        <Route path={`${ARTICLE_PATH}/:articleId`}>
          <ArticlePage />
        </Route>
        <Route path={APPLICATION_ONLINE_STATUS_PAGE}>
          {config.REACT_APP_ENABLE_ATHENA_NEXT_REDIRECT === 'true'
            ? isAppReady && <NextRedirect />
            : isAppReady && <ApplicationStatusOnlinePage />}
        </Route>
        <Route path={PODCAST_PATH}>
          {config.REACT_APP_ENABLE_ATHENA_NEXT_REDIRECT === 'true' &&
            isAppReady && <NextRedirect />}
        </Route>
        <Route path={`${PODCAST_PATH}/:podcastId`}>
          {config.REACT_APP_ENABLE_ATHENA_NEXT_REDIRECT === 'true' &&
            isAppReady && <NextRedirect />}
        </Route>
        <Route path={`${LEARNING_COLLECTION_PATH}/:learningId`}>
          {config.REACT_APP_ENABLE_ATHENA_NEXT_REDIRECT === 'true' &&
            isAppReady && <NextRedirect />}
        </Route>
        <Route path={TOOLS_PATH} component={ToolsPage} />
        <Route exact path={UPGRADE_PATH}>
          {config.REACT_APP_IS_BUY_PAGE_NEXT_REDIRECT_ENABLED === 'true'
            ? isAppReady && <NextRedirect />
            : isAppReady && (
                <ProtectedRoute>
                  <UpgradePage />
                </ProtectedRoute>
              )}
        </Route>
        <Route path="/sitemap" component={SiteMap} />
        <Route path="/support" component={ErrorPage} />
        <Route
          exact
          path={PROMETRICS_COMPLETE_PATH}
          component={PMCompletePage}
        />
        <Route
          exact
          path={WRITING_EXAM_COMPLETE_FINAL_PATH}
          component={WritingExamCompletePage}
        />
        <Route
          path={`${BROWSER_ERROR_PATH}/:examType/:browserName`}
          render={({ match }) => (
            <BrowserErrorPage
              examType={match.params.examType}
              browserName={match.params.browserName}
            />
          )}
        />
        <Route
          exact
          path={PLUS_PROGRAM_REGISTRATION}
          component={PlusProgramWelcome}
        />
        <Route
          path={`${PLUS_PROGRAM_REGISTRATION_COMPLETE}`}
          component={PlusRegCompletePage}
        />
        <Route path={STARTUP_PATH} component={AppInitPage} />
        <Route exact path={API_ERROR_PATH} component={ApiErrorPage} />
        <Route exact path={OFFLINE_ERROR_PATH} component={ApiErrorPage} />
        <Route path={ACCOUNT_SETTINGS_PATH}>
          {isAppReady && <NextRedirect />}
        </Route>
        <Route exact path={ERROR_SCREEN_PATH} component={AppErrorUI} />
        <Route
          exact
          path={WRITING_ERROR_SCREEN_PATH}
          component={WritingErrorUI}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  );
}

App.propTypes = {
  testPageCheck: PropTypes.bool,
};
